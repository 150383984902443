import React from 'react'
import "./Obvestila.css"
import glasilo22 from "../IMAGES/glasilo.pdf"
import glasilo23 from "../IMAGES/glasilo23.pdf"
import glasilo24 from "../IMAGES/glasilo24.pdf"
import voscilo from "../IMAGES/voscilo.jpg"
import ExpandMenu from '../components/ExpandMenu'

export default function Obvestila() {
    return (
        <div className="pages-items obvestila">
            <div/>
            <div className='glasilo'>
                <h1>Glasilo Pod Marelo 2024</h1>
                <a href={glasilo24} download>
                    <h2>Prenesi</h2>
                </a>
            </div>
            <div className='glasilo'>
                <h1>Glasilo Pod Marelo 2023</h1>
                <a href={glasilo23} download>
                    <h2>Prenesi</h2>
                </a>
            </div>
            <div className='glasilo'>
                <h1>Glasilo Pod Marelo 2022</h1>
                <a href={glasilo22} download>
                    <h2>Prenesi</h2>
                </a>
            </div>
        </div>
    )
}
